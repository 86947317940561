import React from 'react'
import ServicesData from './Services.json';

const Services = () => {
  return (
      <section className="services padding-120 ">
          <div className="container">
              <div className="row ">
                  <div className="col-md-4 col-xs-12 ">
                      <div className="service-item h-100">
                          <span className="icon fa-solid fa-graduation-cap mb-3 mt-3"></span>
                          <h4>{ServicesData[0].serviceCaption}</h4>
                          <p>
                              {ServicesData[0].serviceText}
                          </p>
                          <a href="services.html">
                              Read More{" "}
                              <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                              ></i>
                          </a>
                          <br />
                          <br />
                      </div>
                  </div>
                  <div className="col-md-4 col-xs-12  ">
                      <div className="service-item h-100">
                          <span className="icon fa-solid fa-book-open mb-3 mt-3"></span>
                          <h4>{ServicesData[1].serviceCaption}</h4>
                          <p>
                              {ServicesData[1].serviceText}
                          </p>
                          <a href="services.html">
                              Read More{" "}
                              <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                              ></i>
                          </a>
                      </div>
                  </div>
                  <div className="col-md-4 col-xs-12  ">
                      <div className="service-item h-100">
                          <span className="icon fa-solid fa-bars mt-3 mb-3"></span>
                          <h4>{ServicesData[2].serviceCaption}</h4>
                          <p>
                              {ServicesData[2].serviceText}
                          </p>
                          <a href="services.html">
                              Read More{" "}
                              <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                              ></i>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default Services