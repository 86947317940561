import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { useStateValue } from "../../context/myContext";
import axiosInstance from "../../service/axiosInstance";

const ChooseLessonPage = () => {
  const { setMyState } = useStateValue();
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  //GetAll Lessons Program For Choose Lessons
  const [lessonProgram, setLessonProgram] = useState([]);

  const getLessonProgram = () => {
    axiosInstance.get("/lessonPrograms/getAll").then((response) => {
      setLessonProgram(response.data);
    });
  };

  useEffect(() => {
    getLessonProgram();
  }, []);

  const lessonProgramList = lessonProgram;

  const withTeachers = lessonProgram.filter(
    (items7) => items7.teachers.length != 0
  );

  //GetAll Lessons Program
  const [studentLessonProgram, setStudentLessonProgram] = useState([]);

  const getStudentLessonProgram = () => {
    axiosInstance
      .get("/lessonPrograms/getAllLessonProgramByStudent")
      .then((response) => {
        setStudentLessonProgram(response.data);
      });
  };

  useEffect(() => {
    getStudentLessonProgram();
  }, []);

  const studentLessonProgramList = studentLessonProgram;

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 7528 && data.util !== 6982 && (
        <Navigate to="/login" />
      )}

      {((data && data.util === 7528) || (data && data.util === 6982)) && (
        <div>
          {/* Choose Lesson Start*/}
          <Container fluid>
            <Card
              className="mt-4 ms-5 me-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                Choose Lesson Management
              </Card.Header>
            </Card>
          </Container>

          <Container fluid>
            <Formik
              initialValues={{
                lessonProgramId: [],
              }}
              onSubmit={(values, actions) => {
                axiosInstance
                  .post("/students/chooseLesson", values)
                  .then((response) => {
                    if (response.status === 200) {
                      const userInfo = response.data;
                      setMyState({
                        type: "CHOOSELESSON",
                        item: userInfo,
                      });
                      toast.success(userInfo.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                      actions.resetForm();
                      getStudentLessonProgram();
                    }
                  })
                  .catch((error) => {
                    {
                      !error.response.data.validations &&
                        toast.error(error.response.data.message, {
                          position: "top-center",
                          theme: "colored",
                          hideProgressBar: true,
                          autoClose: 1000,
                          closeOnClick: true,
                        });
                    }
                    {
                      error.response.data.validations &&
                        toast.error(
                          error.response.data.validations.lessonProgramId,
                          {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          }
                        );
                    }
                  });
                actions.setSubmitting(false);
              }}
            >
              {(formikk) => (
                <Card
                  className="m-5 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                  onSubmit={formikk.handleSubmit}
                >
                  <Row>
                    <Col>
                      <Card.Header className="fw-bold p-3" as="h5">
                        Choose Lesson
                      </Card.Header>

                      <Table responsive striped bordered hover>
                        <thead>
                          <tr>
                            <th>Choose Lesson</th>
                            <th>Lesson</th>
                            <th>Teacher</th>
                            <th>Day</th>
                            <th>Start Time</th>
                            <th>Stop Time</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                          {withTeachers.map((item2) => (
                            <tr key={item2.id}>
                              <td>
                                <span>
                                  <Form.Check
                                    type="checkbox"
                                    id="lessonProgramId"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={item2.lessonProgramId}
                                  />
                                </span>
                              </td>
                              <td>
                                <span>{item2.lessonName[0].lessonName}</span>
                              </td>
                              <td>
                                <span>
                                  {item2.teachers[0].name}{" "}
                                  {item2.teachers[0].surname}
                                </span>
                              </td>
                              <td>
                                <span>{item2.day}</span>
                              </td>

                              <td>
                                <span>{item2.startTime}</span>
                              </td>

                              <td>
                                <span>{item2.stopTime}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Col className=" pb-3">
                        <Button
                          size="lg"
                          variant="primary"
                          className="fw-semibold"
                          onClick={() => {
                            formikk.submitForm();
                          }}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Card>
              )}
            </Formik>
          </Container>
          {/* Choose Lesson End*/}

          {/* Student GetAll Lesson Program Start*/}
          <Container fluid>
            <Card
              className="m-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Row>
                <Col>
                  <Card.Header className="fw-bold p-3" as="h5">
                    Lesson Program List
                  </Card.Header>

                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>Lesson</th>
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>Stop Time</th>
                      </tr>
                    </thead>
                    <tbody className="table-group-divider">
                      {studentLessonProgramList.map((item3) => (
                        <tr key={item3.id}>
                          <td>
                            <span>{item3.lessonName[0].lessonName}</span>
                          </td>

                          <td>
                            <span>{item3.day}</span>
                          </td>

                          <td>
                            <span>{item3.startTime}</span>
                          </td>

                          <td>
                            <span>{item3.stopTime}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Container>
          {/* Student GetAll Lesson Program End*/}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ChooseLessonPage;
