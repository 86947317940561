import React, { createContext, useMemo, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import reducer, { initialState } from "./reducer";

const MyContext = createContext(null);

export const MyContextProvider = ({ children }) => {
  const [myData, setMyData] = useReducer(reducer, initialState);
  const [myState, setMyState] = useReducer(reducer, initialState);
  const values = useMemo(
    () => ({
      myData,
      setMyData,
      myState,
      setMyState,
    }),
    [myData, setMyData, myState, setMyState]
  );
  return <MyContext.Provider value={values}>{children}</MyContext.Provider>;
};
MyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default MyContext;
export const useStateValue = () => useContext(MyContext);
