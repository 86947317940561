import React from 'react';
import achievementsData from "./Achievements.json";

const Achievements = () => {
    return (
        <section className="achievements section-notch">
            <div className="overlay padding-120">
                <div className="container">
                    <div className="row">
                        {achievementsData.map((achievement, index) => (
                            <div className="col-md-3 col-sm-6 col-xs-12" key={index}>
                                <div className="achievement-item">
                                    <i className={`icon ${achievement.icon} mt-3 mb-3`}></i>
                                    <span className="counter">{achievement.count}</span>
                                    <p>{achievement.title}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Achievements;