import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "react-bootstrap/Pagination";
import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axiosInstance from "../../../service/axiosInstance";
import { useStateValue } from "../../../context/myContext";
import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  birthDay: Yup.string().required("Required"),
  birthPlace: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .min(12, "Minimum 12 character (XXX-XXX-XXXX)")
    .required("Required"),
  ssn: Yup.string()
    .min(11, "Minimum 11 character (XXX-XX-XXXX)")
    .required("Required"),
  username: Yup.string().required("Required"),
  password: Yup.string().min(8, "Minimum 8 character").required("Required"),
});

const ViceDeanManagement = () => {
  //Context
  const { setMyState } = useStateValue();

  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  //offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Vice Dean Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const searchSort = "id";
  const searchtype = "page";
  const [viceDeanPage, setViceDeanPage] = useState([]);
  const [viceDeanPage2, setViceDeanPage2] = useState([]);

  const getViceDeanPage = () => {
    axiosInstance
      .get(
        "/vicedean/search" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage +
          "&sort=" +
          searchSort +
          "&type=" +
          searchtype
      )
      .then((response) => {
        setViceDeanPage(response.data);
        setViceDeanPage2(response.data.content);
      });
  };

  useEffect(() => {
    if (data !== null) getViceDeanPage();
  }, [currentPage]);

  const viceDeanList = viceDeanPage2;

  const totalPages = viceDeanPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (currentPage < Math.ceil(viceDeanPage.totalElements / rowsPerPage)) {
      setCurrentPage(Math.ceil(viceDeanPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (currentPage < Math.ceil(viceDeanPage.totalElements / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Get All Vice Deans
  /* const [viceDeans, setViceDeans] = useState([]);

  const getViceDean = () => {
    axiosInstance.get("/vicedean/getAll").then((response) => {
      setViceDeans(response.data);
    });
  };

  useEffect(() => {
    getViceDean();
  }, []);

  const list = viceDeans; */

  //Edit Vice Dean
  const [editViceDeans, setEditViceDeans] = useState({});

  const getViceDeanForEdit = async (id) => {
    try {
      const response = await axiosInstance
        .get("/vicedean/getViceDeanById/" + id)
        .then((response) => {
          setEditViceDeans(response.data.object);
        });
      handleShow();
    } catch (error) {}
  };

  const name = editViceDeans.name;
  const surname = editViceDeans.surname;
  const birthDay = editViceDeans.birthDay;
  const birthPlace = editViceDeans.birthPlace;
  const phoneNumber = editViceDeans.phoneNumber;
  const ssn = editViceDeans.ssn;
  const username = editViceDeans.username;
  const userId = editViceDeans.userId;

  //Delete Vice Dean
  const handleDelete = (id) => {
    axiosInstance.delete("/vicedean/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
          hideProgressBar: true,
          autoClose: 1000,
          closeOnClick: true,
        });
        getViceDeanPage();
      } else {
        toast.success("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 4984 && data.util !== 6982 && (
        <Navigate to="/login" />
      )}

      <div>
        <Container fluid>
          <Card
            className="mt-4 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
            border="warning"
          >
            <Card.Header className="fw-bold p-3" bg="primary" as="h3">
              Vice Dean Management
            </Card.Header>
          </Card>
        </Container>

        {/* Add Vice Dean Start*/}
        <Container fluid>
          <Formik
            initialValues={{
              name: "",
              surname: "",
              gender: "",
              birthDay: "",
              birthPlace: "",
              phoneNumber: "",
              ssn: "",
              username: "",
              password: "",
            }}
            validationSchema={schema}
            onSubmit={(values, actions) => {
              axiosInstance
                .post("/vicedean/save", values)
                .then((response) => {
                  if (response.status === 200) {
                    const userInfo = response.data;
                    setMyState({
                      type: "VICEDEAN",
                      item: userInfo,
                    });
                    toast.success(userInfo.message, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    actions.resetForm();
                    getViceDeanPage();
                  }
                })
                .catch((error) => {
                  {
                    !error.response.data.validations &&
                      toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                  }
                  {
                    error.response.data.validations &&
                      toast.error(error.response.data.validations.name, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                    toast.error(error.response.data.validations.surname, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.gender, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.birthPlace, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.username, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.phoneNumber, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.ssn, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.birthDay, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.password, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                  }
                });
              actions.setSubmitting(false);
            }}
          >
            {(formikk) => (
              <Card
                className="mt-5 ms-3 me-3 mb-5 text-center border border-3 shadow-sm bg-body rounded"
                border="warning"
                onSubmit={formikk.handleSubmit}
              >
                <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                  Add Vice Dean
                </Card.Header>
                <Card.Body>
                  <>
                    <Form>
                      <Row>
                        <Col md="auto">
                          <FloatingLabel
                            controlId="name"
                            label="Name"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="Name"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.name}
                              isInvalid={!!formikk.errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.name}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md="auto">
                          <FloatingLabel
                            controlId="surname"
                            label="Surname"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="Surname"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.surname}
                              isInvalid={!!formikk.errors.surname}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.surname}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md="auto">
                          <FloatingLabel
                            controlId="birthPlace"
                            label="Birth Place"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="Birth Place"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.birthPlace}
                              isInvalid={!!formikk.errors.birthPlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.birthPlace}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md="auto" className="text-center ">
                          <Form.Label>Gender</Form.Label>
                          <Row>
                            <Col>
                              <Form.Check
                                inline
                                label="Female"
                                name="gender"
                                type="radio"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value="FEMALE"
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                inline
                                label="Male"
                                name="gender"
                                type="radio"
                                onChange={formikk.handleChange}
                                onBlur={formikk.handleBlur}
                                value="MALE"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="birthDay">
                            <Form.Label>Date Of Birth</Form.Label>

                            <Form.Control
                              as="input"
                              type="date"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.birthDay}
                              isInvalid={!!formikk.errors.birthDay}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.birthDay}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md="auto">
                          <FloatingLabel
                            controlId="phoneNumber"
                            label="Phone (XXX-XXX-XXXX)"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="Phone Number"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.phoneNumber}
                              isInvalid={!!formikk.errors.phoneNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.phoneNumber}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md="auto">
                          <FloatingLabel
                            controlId="ssn"
                            label="Ssn (XXX-XX-XXXX)"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="ssn"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.ssn}
                              isInvalid={!!formikk.errors.ssn}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.ssn}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md="auto">
                          <FloatingLabel
                            controlId="username"
                            label="User Name"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="text"
                              placeholder="username"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.username}
                              isInvalid={!!formikk.errors.username}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.username}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col md="auto">
                          <FloatingLabel
                            controlId="password"
                            label="Password"
                            className="mb-4 "
                          >
                            <Form.Control
                              as="input"
                              type="password"
                              placeholder="Password"
                              onChange={formikk.handleChange}
                              onBlur={formikk.handleBlur}
                              value={formikk.values.password}
                              isInvalid={!!formikk.errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikk.errors.password}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <div class="mx-auto p-3">
                            <Button
                              size="lg"
                              variant="primary"
                              className="fw-semibold"
                              onClick={() => {
                                formikk.submitForm();
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Card.Body>
              </Card>
            )}
          </Formik>
          <ToastContainer />
        </Container>
        {/* Add Vice Dean End*/}

        {/* GetAll Vice Dean Start*/}
        <Container fluid>
          <Card
            className="mb-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
            border="warning"
          >
            <Card.Header className="fw-bold p-3" bg="primary" as="h5">
              Vice Dean List
            </Card.Header>
            <Card.Body>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Phone Number</th>
                    <th>Ssn</th>
                    <th>User Name</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {viceDeanList.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <span>
                          {item.name} {item.surname}
                        </span>
                      </td>

                      <td>
                        <span>{item.gender}</span>
                      </td>

                      <td>
                        <span>{item.phoneNumber}</span>
                      </td>

                      <td>
                        <span>{item.ssn}</span>
                      </td>
                      <td>
                        <span>{item.username}</span>
                      </td>
                      <td>
                        <span>
                          <Button
                            variant="outline-info"
                            className="text-dark"
                            onClick={() => {
                              getViceDeanForEdit(item.userId);
                            }}
                          >
                            <i className="fa-solid fa-pencil" />
                            &nbsp; Edit
                          </Button>
                        </span>
                      </td>
                      {/* <td>
                        <span>
                          <Button variant="danger">
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleDelete(item.userId)}
                            />
                          </Button>
                        </span>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Row>
                <Col className="d-flex justify-content-start ps-5">
                  Page {currentPage} of {totalPages}
                </Col>
                <Col>
                  <Pagination className="d-flex justify-content-end pe-3">
                    <Pagination.First onClick={showFirstPage} />
                    <Pagination.Prev onClick={showPrevPage} />

                    <Pagination.Item active>{currentPage}</Pagination.Item>

                    <Pagination.Next onClick={showNextPage} />
                    <Pagination.Last onClick={showLastPage} />
                  </Pagination>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        {/* GetAll Vice Dean End*/}

        {/* Edit Vice Dean Start*/}
        <Modal size="xl" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Edit Vice Dean</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={{
              name: name,
              surname: surname,
              gender: "",
              birthDay: birthDay,
              birthPlace: birthPlace,
              phoneNumber: phoneNumber,
              ssn: ssn,
              username: username,
              password: "",
            }}
            validationSchema={schema}
            onSubmit={(values, actions) => {
              axiosInstance
                .put("/vicedean/update/" + userId, values)
                .then((response) => {
                  if (response.status === 200) {
                    const userInfo = response.data;
                    setMyState({
                      type: "VICEDEANEDIT",
                      item: userInfo,
                    });
                    toast.success(userInfo.message, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    getViceDeanPage();
                  }
                })
                .catch((error) => {
                  {
                    !error.response.data.validations &&
                      toast.error(error.response.data.message, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                  }
                  {
                    error.response.data.validations &&
                      toast.error(error.response.data.validations.name, {
                        position: "top-center",
                        theme: "colored",
                        hideProgressBar: true,
                        autoClose: 1000,
                        closeOnClick: true,
                      });
                    toast.error(error.response.data.validations.surname, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.gender, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.birthPlace, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.username, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.phoneNumber, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.ssn, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.birthDay, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                    toast.error(error.response.data.validations.password, {
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: true,
                      autoClose: 1000,
                      closeOnClick: true,
                    });
                  }
                });
              actions.setSubmitting(false);
            }}
          >
            {(formikk) => (
              <div>
                <Modal.Body>
                  <Card
                    className="text-center border border-3"
                    border="warning"
                    onSubmit={formikk.handleSubmit}
                  >
                    <Card.Body>
                      <>
                        <Form>
                          <Row>
                            <Col md="auto">
                              <FloatingLabel
                                controlId="name"
                                label="Name"
                                className="mb-4 "
                              >
                                <Form.Control
                                  as="input"
                                  type="text"
                                  placeholder="Name"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.name}
                                  isInvalid={!!formikk.errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.name}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md="auto">
                              <FloatingLabel
                                controlId="surname"
                                label="Surname"
                                className="mb-4 "
                              >
                                <Form.Control
                                  as="input"
                                  type="text"
                                  placeholder="Surname"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.surname}
                                  isInvalid={!!formikk.errors.surname}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.surname}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md="auto">
                              <FloatingLabel
                                controlId="birthPlace"
                                label="Birth Place"
                                className="mb-4 "
                              >
                                <Form.Control
                                  as="input"
                                  type="text"
                                  placeholder="Birth Place"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.birthPlace}
                                  isInvalid={!!formikk.errors.birthPlace}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.birthPlace}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md="auto" className="text-center ">
                              <Form.Label>Gender</Form.Label>
                              <Row>
                                <Col>
                                  <Form.Check
                                    inline
                                    label="Female"
                                    name="gender"
                                    type="radio"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value="FEMALE"
                                  />
                                </Col>
                                <Col>
                                  <Form.Check
                                    inline
                                    label="Male"
                                    name="gender"
                                    type="radio"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value="MALE"
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Form.Group className="mb-3" controlId="birthDay">
                                <Form.Label>Date Of Birth</Form.Label>

                                <Form.Control
                                  as="input"
                                  type="date"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.birthDay}
                                  isInvalid={!!formikk.errors.birthDay}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.birthDay}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col md="auto">
                              <FloatingLabel
                                controlId="phoneNumber"
                                label="Phone (XXX-XXX-XXXX)"
                                className="mb-4 "
                              >
                                <Form.Control
                                  as="input"
                                  type="text"
                                  placeholder="Phone Number"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.phoneNumber}
                                  isInvalid={!!formikk.errors.phoneNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.phoneNumber}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md="auto">
                              <FloatingLabel
                                controlId="ssn"
                                label="Ssn (XXX-XX-XXXX)"
                                className="mb-4 "
                              >
                                <Form.Control
                                  as="input"
                                  type="text"
                                  placeholder="ssn"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.ssn}
                                  isInvalid={!!formikk.errors.ssn}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.ssn}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md="auto">
                              <FloatingLabel
                                controlId="username"
                                label="User Name"
                                className="mb-4 "
                              >
                                <Form.Control
                                  as="input"
                                  type="text"
                                  placeholder="username"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.username}
                                  isInvalid={!!formikk.errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.username}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md="auto">
                              <FloatingLabel
                                controlId="password"
                                label="Password"
                                className="mb-4 "
                              >
                                <Form.Control
                                  as="input"
                                  type="password"
                                  placeholder="Password"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value={formikk.values.password}
                                  isInvalid={!!formikk.errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formikk.errors.password}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    </Card.Body>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="lg"
                    variant="primary"
                    className="fw-semibold"
                    onClick={() => {
                      formikk.submitForm();
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Formik>
        </Modal>
        {/* Edit Vice Dean End*/}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViceDeanManagement;
