import React from 'react'
import about from "../../../assets/images/about/about.jpg";
import teachingExperience from './TeachingExperience.json';

const TeachingExperience = () => {
  return (
      <section className="about-two">
          <div className="left">
              <div className="left-in">
                  <div className="title">
                      <div className="t-left">
                          <h2>{teachingExperience.yearsOfExperience}</h2>
                      </div>
                      <div className="t-right mt-2">
                          <h4>Years of professional teaching experience</h4>
                      </div>
                  </div>
                  <ul>
                      <li>
                          <div className="icon fa-solid fa-file-circle-check"></div>

                          <div className="content mt-3">
                              <h4>{teachingExperience.freeTutorials} Free Tutorials</h4>
                              <p>
                                  {teachingExperience.technologyTechniques} {" "}
                              </p>
                          </div>
                      </li>
                      <li>
                          <div className="icon fa-solid fa-chalkboard"></div>
                          <div className="content mt-3">
                              <h4>{teachingExperience.courses} Courses </h4>
                              <p>{teachingExperience.prepareForHigherStudy} </p>
                          </div>
                      </li>
                      <li>
                          <div className="icon fa-solid fa-tablet-button"></div>
                          <div className="content mt-2">
                              <h4>{teachingExperience.freeDocuments} Free documents and eBook Available</h4>
                              <p>
                                  {teachingExperience.certifiedOriginalDocuments}{" "}
                              </p>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
          <div className="right">
              <img src={about} alt="About_Image" className="img-responsive" />
          </div>
      </section>
  )
}

export default TeachingExperience