import React from "react";
import Footers from "./footer/Footers";

const Footer = () => {
  return (
    <footer>
      <Footers/>

    </footer>
  );
};

export default Footer;
