import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Card, Container, Pagination, Col, Row } from "react-bootstrap";
import axiosInstance from "../../service/axiosInstance";

const GradesPage = () => {
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  // Student Info Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const [studentInfoPage, setStudentInfoPage] = useState([]);
  const [studentInfoPage2, setStudentInfoPage2] = useState([]);

  const getStudentInfoPage = () => {
    axiosInstance
      .get(
        "/studentInfo/getAllByStudent" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage
      )
      .then((response) => {
        setStudentInfoPage(response.data);
        setStudentInfoPage2(response.data.content);
      });
  };

  useEffect(() => {
    getStudentInfoPage();
  }, [currentPage]);

  const studentInfoList = studentInfoPage2;

  const totalPages = studentInfoPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (currentPage < Math.ceil(studentInfoPage.totalElements / rowsPerPage)) {
      setCurrentPage(Math.ceil(studentInfoPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (currentPage < Math.ceil(studentInfoPage.totalElements / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Get All StudentsInfo
  /* const [studentInfo, setStudentInfo] = useState([]);

  const getStudentInfo = () => {
    axiosInstance.get("/studentInfo/getAllByStudent").then((response) => {
      setStudentInfo(response.data);
    });
  };

  useEffect(() => {
    getStudentInfo();
  }, []);

  const studentInfoList = studentInfo; */

  //Get All Meets
  const [meets, setMeets] = useState([]);

  const getMeets = () => {
    axiosInstance.get("/meet/getAllMeetByStudent").then((response) => {
      setMeets(response.data);
    });
  };

  useEffect(() => {
    getMeets();
  }, []);

  const meetsList = meets;

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 7528 && data.util !== 6982 && (
        <Navigate to="/login" />
      )}
      {((data && data.util === 7528) || (data && data.util === 6982)) && (
        <div>
          {/* Student Info List Start*/}
          <Container fluid>
            <Card
              className="mb-5 ms-5 me-5 mt-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                Student Info List
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Lesson Name</th>
                      <th>Absentee</th>
                      <th>Midterm Exam</th>
                      <th>Final Exam</th>
                      <th>Note</th>
                      <th>Info Note</th>
                      <th>Average</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {studentInfoList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <span>{item.lessonName}</span>
                        </td>

                        <td>
                          <span>{item.absentee}</span>
                        </td>
                        <td>
                          <span>{item.midtermExam}</span>
                        </td>
                        <td>
                          <span>{item.finalExam}</span>
                        </td>
                        <td>
                          <span>{item.note}</span>
                        </td>
                        <td>
                          <span>{item.infoNote}</span>
                        </td>
                        <td>
                          <span>{item.average}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col className="d-flex justify-content-start ps-5">
                    Page {currentPage} of {totalPages}
                  </Col>
                  <Col>
                    <Pagination className="d-flex justify-content-end pe-3">
                      <Pagination.First onClick={showFirstPage} />
                      <Pagination.Prev onClick={showPrevPage} />

                      <Pagination.Item active>{currentPage}</Pagination.Item>

                      <Pagination.Next onClick={showNextPage} />
                      <Pagination.Last onClick={showLastPage} />
                    </Pagination>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
          {/* Student Info List End*/}

          {/* GetAll Meet Start*/}
          <Container fluid>
            <Card
              className="mb-5 ms-5 me-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                Meet List
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>

                      <th>Start Time</th>
                      <th>Stop Time</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {meetsList.map((item2) => (
                      <tr key={item2.id}>
                        <td>
                          <span>{item2.date}</span>
                        </td>

                        <td>
                          <span>{item2.startTime}</span>
                        </td>

                        <td>
                          <span>{item2.stopTime}</span>
                        </td>
                        <td>
                          <span>{item2.description}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
          {/* GetAll Meet End*/}
        </div>
      )}
    </div>
  );
};

export default GradesPage;
