import React from 'react'
import eventData from "./../Events/Events.json";
import event1 from "./../../../assets/images/event/event_01.jpg";
import event2 from "./../../../assets/images/event/event_02.jpg";
import event3 from "./../../../assets/images/event/event_03.jpg";

const UpcomingEvents = () => {
    const imgData = [event1, event2, event3];
    const slicedEventData = eventData.slice(0, 3);
    return (
        <div>
            <section className="event padding-120">
                <div className="container">
                    <div className="section-header">
                        <h3>Our Upcoming Events</h3>
                        <p>
                            We regularly organize social activities for our students within the school setting. Join us for a variety of exciting events and make lasting memories with your fellow students!
                        </p>
                    </div>
                    <div className="event-items">
                        <div className="row">
                            {slicedEventData.map((event, index) => (
                                <div className="col-md-4 col-sm-6 col-xs-12  mt-3" key={index}>
                                    <div className="event-item h-100">
                                        <div className="event-image">
                                            <img
                                                src={imgData[index]}
                                                alt={`Event_Image ${index + 1}`} className="img-responsive"
                                            />
                                            <div className="date">
                                                <span>{event.date1}</span>
                                                <p>{event.date2}</p>
                                            </div>
                                        </div>
                                        <div className="event-content">
                                            <h4>{event.title}</h4>
                                            <p>
                                                {event.desc}
                                            </p>
                                            <a href="events" className="button-default">
                                                Join Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default UpcomingEvents