import React from 'react'

import eventData from "./Events.json";
import event01 from "./../../../assets/images/event/event_01.jpg";
import event02 from "./../../../assets/images/event/event_02.jpg";
import event03 from "./../../../assets/images/event/event_03.jpg";
import event04 from "./../../../assets/images/event/event_04.jpg";
import event05 from "./../../../assets/images/event/event_05.jpg";
import event06 from "./../../../assets/images/event/event_06.jpg";

const Event = () => {
  const imgData = [event01, event02, event03, event04, event05, event06];
  return (
    <div>
      <section className="event padding-120">
        <div className="container">
          <div className="event-items">
            <div className="row">
              {eventData.map((event, index) => (
                <div className="col-md-4 col-sm-6 col-xs-12  mt-3" key={index}>
                  <div className="event-item h-100">
                    <div className="event-image">
                      <img
                        src={imgData[index]}
                        alt={`Event_Image ${index + 1}`}                        className="img-responsive"
                      />
                      <div className="date">
                        <span>{event.date1}</span>
                        <p>{event.date2}</p>
                      </div>
                    </div>
                    <div className="event-content">
                      <h4>{event.title}</h4>
                      <ul>
                        <li>
                          <span>
                            <i
                              className="fa-sharp fa-solid fa-clock"
                              aria-hidden="true"
                            ></i>
                          </span>
                          {event.time}
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-home" aria-hidden="true"></i>
                          </span>
                          {event.location}
                        </li>
                      </ul>
                      <p>
                        {event.desc}
                      </p>
                      <a href="events" className="button-default">
                        Join Now
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Event