import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../service/axiosInstance";

const GuestUser = () => {
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  // GuestUser Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "5";
  const [guestUserPage, setGuestUserPage] = useState([]);
  const [guestUserPage2, setGuestUserPage2] = useState([]);

  const getGuestUserPage = () => {
    axiosInstance
      .get(
        "/guestUser/getAll" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage
      )
      .then((response) => {
        setGuestUserPage(response.data);
        setGuestUserPage2(response.data.content);
      });
  };

  useEffect(() => {
    if (data !== null) getGuestUserPage();
  }, [currentPage]);

  const guestUserList = guestUserPage2;

  const totalPages = guestUserPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (currentPage < Math.ceil(guestUserPage.totalElements / rowsPerPage)) {
      setCurrentPage(Math.ceil(guestUserPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (currentPage < Math.ceil(guestUserPage.totalElements / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Delete Guest User
  const handleDelete = (id) => {
    axiosInstance.delete("/guestUser/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
        });
        getGuestUserPage();
      } else {
        toast.success("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 6982 && <Navigate to="/login" />}

      {data && data.util === 6982 && (
        <div>
          {/* Guest User List Start*/}
          <Container fluid>
            <Card
              className="mb-5 ms-3 me-3 mt-5 text-center border border-3 shadow-sm bg-body rounded"
              border="warning"
            >
              <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                Guest User List
              </Card.Header>
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Ssn</th>
                      <th>User Name</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {guestUserList.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <span>
                            {item.name} {item.surname}
                          </span>
                        </td>

                        <td>
                          <span>{item.phoneNumber}</span>
                        </td>

                        <td>
                          <span>{item.ssn}</span>
                        </td>
                        <td>
                          <span>{item.username}</span>
                        </td>

                        <td>
                          <span>
                            <Button
                              variant="danger"
                              onClick={() => handleDelete(item.id)}
                            >
                              <i className="fa-solid fa-trash" />
                            </Button>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row>
                  <Col className="d-flex justify-content-start ps-5">
                    Page {currentPage} of {totalPages}
                  </Col>
                  <Col>
                    <Pagination className="d-flex justify-content-end pe-3">
                      <Pagination.First onClick={showFirstPage} />
                      <Pagination.Prev onClick={showPrevPage} />

                      <Pagination.Item active>{currentPage}</Pagination.Item>

                      <Pagination.Next onClick={showNextPage} />
                      <Pagination.Last onClick={showLastPage} />
                    </Pagination>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
      )}
      {/* GetAll Contact End*/}
      <ToastContainer />
    </div>
  );
};

export default GuestUser;
