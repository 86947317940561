import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <section className="error-page padding-120">
      <div className="container">
        <div className="row mb-4 mt-4">
          <div className="col d-flex justify-content-center align-items-center">
            <div className="error-content">
              <h2>404</h2>
              <h3>Oops, This Page Not Be Found!</h3>
              <p>
                We are really sorry but the page you requested is missing :(
              </p>
              <Link to="/">
                <Button>Go Back Home</Button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 hidden-sm hidden-xs">
            <div className="error-icon">
              <span className="fa-solid fa-shield-cat"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page404;
