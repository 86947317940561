import React, { useState } from 'react'
import { Button, Carousel, Col, Row } from 'react-bootstrap'
import carouselData from './Carousels.json';

import banner01 from "../../../assets/images/banner/banner_4.01.jpg";
import banner02 from "../../../assets/images/banner/banner_4.02.jpg";
import banner03 from "../../../assets/images/banner/banner_4.03.jpg";

const Carousels = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Carousel
            nextIcon={false}
            prevIcon={false}
            controls={false}
            activeIndex={index}
            onSelect={handleSelect}
        >
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={banner01}
                    alt="First slide"
                    style={{ filter: "brightness(40%)" }}
                />
                <Carousel.Caption>
                    <Row
                        className="d-flex justify-content-start"
                        style={{
                            margin: "0rem 0rem 5rem -10.9rem",
                            fontFamily: "fa-style-family",
                        }}
                    >
                        <Col>
                            <Row>
                                <Col lg="10">
                                    <p
                                        className="float-start ms-2 fs-1"
                                    /*                       style={{
                                                            fontSize: "3.2rem",
                                                          }} */
                                    >
                                        {carouselData[0].text}
                                    </p>
                                </Col>
                                <Col lg="8">
                                    <Button className="ms-5 me-4">Read More</Button>
                                    <Button>Buy Now</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="5"></Col>
                    </Row>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={banner02}
                    alt="Second slide"
                    style={{ filter: "brightness(50%)" }}
                />

                <Carousel.Caption>
                    <Row
                        className="d-flex justify-content-start"
                        style={{
                            margin: "0rem 0rem 5rem -10.9rem",
                            fontFamily: "fa-style-family",
                        }}
                    >
                        <Col>
                            <Row>
                                <Col lg="10">
                                    <p
                                        className="float-start ms-2 fs-1"
                                    /*                       style={{
                                                            fontSize: "2.9rem",
                                                          }} */
                                    >
                                        {carouselData[1].text}
                                    </p>
                                </Col>
                                <Col lg="9">
                                    <Button className="ms-5 me-4">Read More</Button>
                                    <Button>Buy Now</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="5"></Col>
                    </Row>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={banner03}
                    alt="Third slide"
                    style={{ filter: "brightness(50%)" }}
                />

                <Carousel.Caption>
                    <Row
                        className="d-flex justify-content-start"
                        style={{
                            margin: "0rem 0rem 5rem -10.9rem",
                            fontFamily: "fa-style-family",
                        }}
                    >
                        <Col>
                            <Row>
                                <Col lg="10">
                                    <p
                                        className="float-start ms-2 fs-1"
                                    /*                       style={{
                                                            fontSize: "2.9rem",
                                                          }} */
                                    >
                                        {carouselData[2].text}
                                    </p>
                                </Col>
                                <Col lg="9">
                                    <Button className="ms-5 me-4">Read More</Button>
                                    <Button>Buy Now</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="5"></Col>
                    </Row>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default Carousels
