import React from "react";
import Carousels from "./Home/Carousels";
import Services from "./Home/Services";
import Programs from "./Home/Programs";
import Achievements from "./Home/Achievements";
import Teachers from "./Home/Teachers";
import TeachingExperience from "./Home/TeachingExperience";
import FromOurBlog from "./Home/FromOurBlog";
import UpcomingEvents from "./Home/UpcomingEvents";

const Home = () => {
  return (
    <div>
      <Carousels />
      <Services />
      <Programs />
      <Achievements />
      <Teachers />
      <TeachingExperience />
      <FromOurBlog />
      <UpcomingEvents/>
    </div>
  );
};

export default Home;
