export const initialState = {
  userInfo: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, userInfo: action.item };
    case "ADMIN":
      return { ...state, userInfo: action.item };
    case "DEAN":
      return { ...state, userInfo: action.item };
    case "DEANEDIT":
      return { ...state, userInfo: action.item };
    case "VICEDEAN":
      return { ...state, userInfo: action.item };
    case "VICEDEANEDIT":
      return { ...state, userInfo: action.item };
    case "EDUCATIONTERM":
      return { ...state, userInfo: action.item };
    case "LESSON":
      return { ...state, userInfo: action.item };
    case "LESSONPROGRAM":
      return { ...state, userInfo: action.item };
    case "TEACHER":
      return { ...state, userInfo: action.item };
    case "TEACHEREDIT":
      return { ...state, userInfo: action.item };
    case "STUDENT":
      return { ...state, userInfo: action.item };
    case "STUDENTEDIT":
      return { ...state, userInfo: action.item };
    case "STUDENTINFO":
      return { ...state, userInfo: action.item };
    case "STUDENTINFOEDIT":
      return { ...state, userInfo: action.item };
    case "CHOOSELESSON":
      return { ...state, userInfo: action.item };
    case "MEET":
      return { ...state, userInfo: action.item };
    case "MEETEDIT":
      return { ...state, userInfo: action.item };
    case "LOGOUT":
      return { ...state, userInfo: null };
    default:
      return state;
  }
};

export default reducer;
