import axios from "axios";

function getToken() {
  let auth = localStorage.getItem("auth");
  if (auth) {
    auth = JSON.parse(auth);
  }
  return auth ? `${auth.token}` : null;
}

const axiosInstance = axios.create({
  baseURL: "https://mycampusmates.com/app/",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    config.headers["Accept"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axiosInstance;
