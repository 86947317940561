import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import { Form, FloatingLabel } from "react-bootstrap";
import axiosInstance from "../../service/axiosInstance";
import * as Yup from "yup";
import Contacts from "./Contact/contacts";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

const Contact = () => {
  return (
    <div>

      <Contacts/>

      <ToastContainer />
    </div>
  );
};

export default Contact;
