import React from "react";
import Course from "./Courses/Course";

const Courses = () => {
  return (
    <div>

      <Course/>

    </div>
  );
};

export default Courses;
