import React from "react";
import event01 from "../../assets/images/event/event_01.jpg";
import event02 from "../../assets/images/event/event_02.jpg";
import event03 from "../../assets/images/event/event_03.jpg";
import event04 from "../../assets/images/event/event_04.jpg";
import event05 from "../../assets/images/event/event_05.jpg";
import event06 from "../../assets/images/event/event_06.jpg";
import Event from "./Events/Event";

const Events = () => {
  return (
    <div>
      <section class="page-header">
        <div class="overlay">
          <div class="container">
            <h3>Events Page</h3>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li> - </li>
              <li>Event</li>
            </ul>
          </div>
        </div>
      </section>
      <Event/>
    </div>
  );
};

export default Events;
