import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Pagination from "react-bootstrap/Pagination";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
  Modal,
} from "react-bootstrap";
import { Formik } from "formik";
import { useStateValue } from "../../context/myContext";
import axiosInstance from "../../service/axiosInstance";
import Select from "react-select";
import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  birthDay: Yup.string().required("Required"),
  birthPlace: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .min(12, "Minimum 12 character (XXX-XXX-XXXX)")
    .required("Required"),
  ssn: Yup.string()
    .min(11, "Minimum 11 character (XXX-XX-XXXX)")
    .required("Required"),
  username: Yup.string().required("Required"),
  password: Yup.string().min(8, "Minimum 8 character").required("Required"),
});

const TeacherPage = () => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedOptions02, setSelectedOptions02] = useState();

  const { setMyState } = useStateValue();
  let Role = localStorage.getItem("util");
  const data = JSON.parse(Role);

  //Tab
  const [key, setKey] = useState("teachersList");

  //Offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Get All Lessons Program
  const [lessonProgram, setLessonProgram] = useState([]);

  const getLessonProgram = () => {
    axiosInstance.get("/lessonPrograms/getAll").then((response) => {
      setLessonProgram(response.data);
    });
  };

  useEffect(() => {
    getLessonProgram();
  }, []);

  const list = lessonProgram;

  // Teacher Pagination
  const [currentTPage, setCurrentTPage] = useState(1);
  const rowsPerTPage = "20";
  const searchTSort = "id";
  const searchTtype = "page";
  const [teacherTPage, setTeacherTPage] = useState([]);
  const [teacherTPage2, setTeacherTPage2] = useState([]);

  const getTeacherPage = () => {
    axiosInstance
      .get(
        "/teachers/search" +
          "?page=" +
          (currentTPage - 1) +
          "&size=" +
          rowsPerTPage +
          "&sort=" +
          searchTSort +
          "&type=" +
          searchTtype
      )
      .then((response) => {
        setTeacherTPage(response.data);
        setTeacherTPage2(response.data.content);
      });
  };

  useEffect(() => {
    getTeacherPage();
  }, [currentTPage]);

  const list2 = teacherTPage2;

  const totalTPages = teacherTPage.totalPages;

  const showFirstTPage = () => {
    let firstPage = 1;
    if (currentTPage > firstPage) {
      setCurrentTPage(firstPage);
    }
  };

  const showLastTPage = () => {
    if (currentTPage < Math.ceil(teacherTPage.totalElements / rowsPerTPage)) {
      setCurrentTPage(Math.ceil(teacherTPage.totalElements / rowsPerTPage));
    }
  };

  const showNextTPage = () => {
    if (currentTPage < Math.ceil(teacherTPage.totalElements / rowsPerTPage)) {
      setCurrentTPage(currentTPage + 1);
    }
  };

  const showPrevTPage = () => {
    let prevPage = 1;
    if (currentTPage > prevPage) {
      setCurrentTPage(currentTPage - prevPage);
    }
  };

  //Get All Teachers
  /* const [teachers, setTeachers] = useState([]);

  const getTeachers = () => {
    axiosInstance.get("/teachers/getAll").then((response) => {
      setTeachers(response.data);
    });
  };

  useEffect(() => {
    getTeachers();
  }, []);

  const teacherList = teachers; */

  //Edit Teacher
  const [editTeachers, setEditTeachers] = useState({});

  const getTeacherForEdit = async (id) => {
    try {
      const response = await axiosInstance
        .get("/teachers/getSavedTeacherById/" + id)
        .then((response) => {
          setEditTeachers(response.data.object);
        });
      handleShow();
    } catch (error) {}
  };

  const name = editTeachers.name;
  const surname = editTeachers.surname;
  const birthDay = editTeachers.birthDay;
  const birthPlace = editTeachers.birthPlace;
  const email = editTeachers.email;
  const phoneNumber = editTeachers.phoneNumber;
  const ssn = editTeachers.ssn;
  const username = editTeachers.username;
  const userId = editTeachers.userId;

  //Delete Teacher
  const handleDelete = (id) => {
    axiosInstance.delete("/teachers/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
        });
        getTeacherPage();
      } else {
        toast.success("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  //Get All Advisor Teachers
  // const [advisorTeachers, setAdvisorTeachers] = useState([]);

  // const getAdvisorTeachers = () => {
  //   axiosInstance.get("/advisorTeacher/getAll").then((response) => {
  //     setAdvisorTeachers(response.data);
  //   });
  // };

  // useEffect(() => {
  //   getAdvisorTeachers();
  // }, []);

  // const advisorTeacherList = advisorTeachers;

  // AdvisorTeacher Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = "20";
  const searchSort = "id";
  const searchtype = "page";
  const [advisorTeacherPage, setAdvisorTeacherPage] = useState([]);
  const [advisorTeacherPage2, setAdvisorTeacherPage2] = useState([]);

  const getAdvisorTeacherPage = () => {
    axiosInstance
      .get(
        "/advisorTeacher/search" +
          "?page=" +
          (currentPage - 1) +
          "&size=" +
          rowsPerPage +
          "&sort=" +
          searchSort +
          "&type=" +
          searchtype
      )
      .then((response) => {
        setAdvisorTeacherPage(response.data);
        setAdvisorTeacherPage2(response.data.content);
      });
  };

  useEffect(() => {
    getAdvisorTeacherPage();
  }, [currentPage]);

  const list3 = advisorTeacherPage2;

  const totalPages = advisorTeacherPage.totalPages;

  const showFirstPage = () => {
    let firstPage = 1;
    if (currentPage > firstPage) {
      setCurrentPage(firstPage);
    }
  };

  const showLastPage = () => {
    if (
      currentPage < Math.ceil(advisorTeacherPage.totalElements / rowsPerPage)
    ) {
      setCurrentPage(Math.ceil(advisorTeacherPage.totalElements / rowsPerPage));
    }
  };

  const showNextPage = () => {
    if (
      currentPage < Math.ceil(advisorTeacherPage.totalElements / rowsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const showPrevPage = () => {
    let prevPage = 1;
    if (currentPage > prevPage) {
      setCurrentPage(currentPage - prevPage);
    }
  };

  //Delete Advisor Teacher
  const handleDelete2 = (id) => {
    axiosInstance.delete("/advisorTeacher/delete/" + id).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          theme: "colored",
        });
        getAdvisorTeacherPage();
      } else {
        toast.success("Unable to Deleted the User", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const optionList = lessonProgram.map((items2) => ({
    value: items2.lessonProgramId,
    label: items2.lessonName[0].lessonName,
  }));

  const lessonForSearch = selectedOptions
    ? selectedOptions && selectedOptions.map((items) => items.value)
    : "";

  const lessonForSearch02 = selectedOptions02
    ? selectedOptions02 && selectedOptions02.map((items) => items.value)
    : "";

  return (
    <div>
      {!data && <Navigate to="/login" />}
      {data && data.util !== 5487 && data.util !== 6982 && (
        <Navigate to="/login" />
      )}

      {((data && data.util === 5487) || (data && data.util === 6982)) && (
        <div>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-2 fw-bold fs-4"
            justify
          >
            <Tab eventKey="teachersList" title="Teachers">
              <Container fluid>
                <Card
                  className="mt-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h3">
                    Teacher Management
                  </Card.Header>
                </Card>
              </Container>
              {/* Add Teacher Start*/}
              <Container fluid>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: "",
                    surname: "",
                    birthDay: "",
                    birthPlace: "",
                    email: "",
                    gender: "",
                    isAdvisorTeacher: "false",
                    lessonsIdList: lessonForSearch,
                    password: "",
                    phoneNumber: "",
                    ssn: "",
                    username: "",
                  }}
                  validationSchema={schema}
                  onSubmit={(values, actions) => {
                    axiosInstance
                      .post("/teachers/save", values)
                      .then((response) => {
                        if (response.status === 200) {
                          const userInfo = response.data;
                          setMyState({
                            type: "TEACHER",
                            item: userInfo,
                          });
                          toast.success(userInfo.message, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          actions.resetForm();
                          getTeacherPage();
                          getAdvisorTeacherPage();
                        }
                      })
                      .catch((error) => {
                        {
                          !error.response.data.validations &&
                            toast.error(error.response.data.message, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                        }
                        {
                          error.response.data.validations &&
                            toast.error(error.response.data.validations.name, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                          toast.error(error.response.data.validations.surname, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(error.response.data.validations.email, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(
                            error.response.data.validations.isAdvisorTeacher,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.lessonsIdList,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );

                          toast.error(error.response.data.validations.gender, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(
                            error.response.data.validations.birthPlace,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.username,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.phoneNumber,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(error.response.data.validations.ssn, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(
                            error.response.data.validations.birthDay,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.password,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                        }
                      });
                    actions.setSubmitting(false);
                  }}
                >
                  {(formikk) => (
                    <Card
                      className="mt-5 ms-3 me-3 text-center border border-3 shadow-sm bg-body rounded"
                      border="warning"
                      onSubmit={formikk.handleSubmit}
                    >
                      <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                        Add Teacher
                      </Card.Header>
                      <Card.Body>
                        <>
                          <Form>
                            <Row>
                              <Col lg="4">
                                <Form.Label className="fw-semibold mt-4">
                                  Choose Lessons
                                </Form.Label>

                                <Select
                                  options={optionList}
                                  placeholder="Select lesson"
                                  value={selectedOptions}
                                  onChange={setSelectedOptions}
                                  isSearchable={true}
                                  isMulti
                                />
                              </Col>
                              <Col md="auto" className="mt-5">
                                <FloatingLabel
                                  controlId="name"
                                  label="Name"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Name"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.name}
                                    isInvalid={!!formikk.errors.name}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.name}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>

                              <Col md="auto" className="mt-5">
                                <FloatingLabel
                                  controlId="surname"
                                  label="Surname"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Surname"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.surname}
                                    isInvalid={!!formikk.errors.surname}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.surname}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto" className="mt-5">
                                <FloatingLabel
                                  controlId="birthPlace"
                                  label="Birth Place"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Birth Place"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.birthPlace}
                                    isInvalid={!!formikk.errors.birthPlace}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.birthPlace}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="auto" className="pt-4">
                                <FloatingLabel
                                  controlId="email"
                                  label="Email"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="email"
                                    placeholder="Email Address"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.email}
                                    isInvalid={!!formikk.errors.email}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.email}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto" className="pt-4">
                                <FloatingLabel
                                  controlId="phoneNumber"
                                  label="Phone (XXX-XXX-XXXX)"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="Phone Number"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.phoneNumber}
                                    isInvalid={!!formikk.errors.phoneNumber}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.phoneNumber}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col className=" pt-4">
                                <Form.Check
                                  type="checkbox"
                                  id="isAdvisorTeacher"
                                  label="Is Advisor Teacher"
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value="true"
                                />
                              </Col>

                              <Col md="auto" className="text-center pt-4">
                                <Form.Label>Gender</Form.Label>
                                <Row>
                                  <Col>
                                    <Form.Check
                                      inline
                                      label="Female"
                                      name="gender"
                                      type="radio"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value="FEMALE"
                                    />
                                  </Col>
                                  <Col>
                                    <Form.Check
                                      inline
                                      label="Male"
                                      name="gender"
                                      type="radio"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value="MALE"
                                    />
                                  </Col>
                                </Row>
                              </Col>

                              <Col>
                                <Form.Group
                                  className="mb-3 pt-4"
                                  controlId="birthDay"
                                >
                                  <Form.Label>Date Of Birth</Form.Label>

                                  <Form.Control
                                    as="input"
                                    type="date"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.birthDay}
                                    isInvalid={!!formikk.errors.birthDay}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.birthDay}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="auto" className="pt-2">
                                <FloatingLabel
                                  controlId="ssn"
                                  label="Ssn (XXX-XX-XXXX)"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="ssn"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.ssn}
                                    isInvalid={!!formikk.errors.ssn}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.ssn}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto" className="pt-2">
                                <FloatingLabel
                                  controlId="username"
                                  label="User Name"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="text"
                                    placeholder="username"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.username}
                                    isInvalid={!!formikk.errors.username}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.username}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md="auto" className="pt-2">
                                <FloatingLabel
                                  controlId="password"
                                  label="Password"
                                  className="mb-4 "
                                >
                                  <Form.Control
                                    as="input"
                                    type="password"
                                    placeholder="Password"
                                    onChange={formikk.handleChange}
                                    onBlur={formikk.handleBlur}
                                    value={formikk.values.password}
                                    isInvalid={!!formikk.errors.password}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formikk.errors.password}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>

                              <Col className="mx-auto ps-3 pt-3">
                                <div>
                                  <Button
                                    size="lg"
                                    variant="primary"
                                    className="fw-semibold"
                                    onClick={() => {
                                      formikk.submitForm();
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      </Card.Body>
                    </Card>
                  )}
                </Formik>
              </Container>

              {/* Add Teacher End*/}

              {/* GetAll Teacher Start*/}
              <Container fluid>
                <Card
                  className="mt-5 ms-3 me-3 mb-5 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                    Teacher List
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped bordered hover>
                      <thead>
                        <tr>
                          <th>Name</th>

                          <th>Phone Number</th>
                          <th>Ssn</th>
                          <th>User Name</th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {list2.map((item2) => (
                          <tr key={item2.id}>
                            <td>
                              <span>
                                {item2.name} {item2.surname}
                              </span>
                            </td>
                            <td>
                              <span>{item2.phoneNumber}</span>
                            </td>
                            <td>
                              <span>{item2.ssn}</span>
                            </td>
                            <td>
                              <span>{item2.username}</span>
                            </td>
                            <td>
                              <span>
                                <Button
                                  variant="outline-info"
                                  className="text-dark"
                                  onClick={() => {
                                    getTeacherForEdit(item2.userId);
                                  }}
                                >
                                  <i className="fa-solid fa-pencil" />
                                  &nbsp; Edit
                                </Button>
                              </span>
                            </td>
                            {/* <td>
                              <span>
                                <Button
                                  variant="danger"
                                  onClick={() => handleDelete(item2.userId)}
                                >
                                  <i className="fa-solid fa-trash" />
                                </Button>
                              </span>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row>
                      <Col className="d-flex justify-content-start ps-5">
                        Page {currentTPage} of {totalTPages}
                      </Col>
                      <Col>
                        <Pagination className="d-flex justify-content-end pe-3">
                          <Pagination.First onClick={showFirstTPage} />
                          <Pagination.Prev onClick={showPrevTPage} />

                          <Pagination.Item active>
                            {currentTPage}
                          </Pagination.Item>

                          <Pagination.Next onClick={showNextTPage} />
                          <Pagination.Last onClick={showLastTPage} />
                        </Pagination>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Container>
              {/* GetAll Teacher End*/}

              {/* Edit Teacher Start*/}

              <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title className="text-center">
                    Edit Teacher
                  </Modal.Title>
                </Modal.Header>

                <Formik
                  enableReinitialize
                  initialValues={{
                    name: name,
                    surname: surname,
                    gender: "",
                    birthDay: birthDay,
                    birthPlace: birthPlace,
                    email: email,
                    isAdvisorTeacher: "false",
                    lessonsIdList: lessonForSearch02,
                    phoneNumber: phoneNumber,
                    ssn: ssn,
                    username: username,
                    password: "",
                  }}
                  validationSchema={schema}
                  onSubmit={(values, actions) => {
                    axiosInstance
                      .put("/teachers/update/" + userId, values)
                      .then((response) => {
                        if (response.status === 200) {
                          const userInfo = response.data;
                          setMyState({
                            type: "TEACHEREDIT",
                            item: userInfo,
                          });
                          toast.success(userInfo.message, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          getTeacherPage();
                          getAdvisorTeacherPage();
                        }
                      })
                      .catch((error) => {
                        {
                          !error.response.data.validations &&
                            toast.error(error.response.data.message, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                        }
                        {
                          error.response.data.validations &&
                            toast.error(error.response.data.validations.name, {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            });
                          toast.error(error.response.data.validations.surname, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(error.response.data.validations.email, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(
                            error.response.data.validations.isAdvisorTeacher,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.lessonsIdList,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );

                          toast.error(error.response.data.validations.gender, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(
                            error.response.data.validations.birthPlace,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.username,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.phoneNumber,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(error.response.data.validations.ssn, {
                            position: "top-center",
                            theme: "colored",
                            hideProgressBar: true,
                            autoClose: 1000,
                            closeOnClick: true,
                          });
                          toast.error(
                            error.response.data.validations.birthDay,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                          toast.error(
                            error.response.data.validations.password,
                            {
                              position: "top-center",
                              theme: "colored",
                              hideProgressBar: true,
                              autoClose: 1000,
                              closeOnClick: true,
                            }
                          );
                        }
                      });
                    actions.setSubmitting(false);
                  }}
                >
                  {(formikk) => (
                    <div>
                      <Modal.Body>
                        <Card
                          className="ms-4 me-4 text-center border border-3 shadow-sm bg-body rounded"
                          border="warning"
                          onSubmit={formikk.handleSubmit}
                        >
                          <Card.Body>
                            <>
                              <Form>
                                <Row>
                                  <Col lg="4">
                                    <Form.Label className="fw-semibold mt-4">
                                      Choose Lessons
                                    </Form.Label>
                                    <Select
                                      options={optionList}
                                      placeholder="Select lesson"
                                      value={selectedOptions02}
                                      onChange={setSelectedOptions02}
                                      isSearchable={true}
                                      isMulti
                                    />
                                  </Col>

                                  <Col md="auto" className="mt-5">
                                    <FloatingLabel
                                      controlId="name"
                                      label="Name"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="text"
                                        placeholder="Name"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.name}
                                        isInvalid={!!formikk.errors.name}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.name}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>

                                  <Col md="auto" className="mt-5">
                                    <FloatingLabel
                                      controlId="surname"
                                      label="Surname"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="text"
                                        placeholder="Surname"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.surname}
                                        isInvalid={!!formikk.errors.surname}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.surname}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>
                                  <Col md="auto" className="mt-5">
                                    <FloatingLabel
                                      controlId="birthPlace"
                                      label="Birth Place"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="text"
                                        placeholder="Birth Place"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.birthPlace}
                                        isInvalid={!!formikk.errors.birthPlace}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.birthPlace}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="auto" className="pt-4">
                                    <FloatingLabel
                                      controlId="email"
                                      label="Email"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="email"
                                        placeholder="Email Address"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.email}
                                        isInvalid={!!formikk.errors.email}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.email}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>

                                  <Col md="auto" className="pt-4">
                                    <FloatingLabel
                                      controlId="phoneNumber"
                                      label="Phone (XXX-XXX-XXXX)"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="text"
                                        placeholder="Phone Number"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.phoneNumber}
                                        isInvalid={!!formikk.errors.phoneNumber}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.phoneNumber}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>
                                  <Col md="auto" className="pt-4">
                                    <FloatingLabel
                                      controlId="ssn"
                                      label="Ssn (XXX-XX-XXXX)"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="text"
                                        placeholder="ssn"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.ssn}
                                        isInvalid={!!formikk.errors.ssn}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.ssn}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>
                                  <Col className=" pt-4">
                                    <Form.Check
                                      type="checkbox"
                                      id="isAdvisorTeacher"
                                      label="Is Advisor Teacher"
                                      onChange={formikk.handleChange}
                                      onBlur={formikk.handleBlur}
                                      value="true"
                                    />
                                  </Col>

                                  <Col md="auto" className="text-center pt-4">
                                    <Form.Label>Gender</Form.Label>
                                    <Row>
                                      <Col>
                                        <Form.Check
                                          inline
                                          label="Female"
                                          name="gender"
                                          type="radio"
                                          onChange={formikk.handleChange}
                                          onBlur={formikk.handleBlur}
                                          value="FEMALE"
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Check
                                          inline
                                          label="Male"
                                          name="gender"
                                          type="radio"
                                          onChange={formikk.handleChange}
                                          onBlur={formikk.handleBlur}
                                          value="MALE"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-3 pt-1"
                                      controlId="birthDay"
                                    >
                                      <Form.Label>Date Of Birth</Form.Label>

                                      <Form.Control
                                        as="input"
                                        type="date"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.birthDay}
                                        isInvalid={!!formikk.errors.birthDay}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.birthDay}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col md="auto" className="pt-3">
                                    <FloatingLabel
                                      controlId="username"
                                      label="User Name"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="text"
                                        placeholder="username"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.username}
                                        isInvalid={!!formikk.errors.username}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.username}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>
                                  <Col md="auto" className="pt-3">
                                    <FloatingLabel
                                      controlId="password"
                                      label="Password"
                                      className="mb-4 "
                                    >
                                      <Form.Control
                                        as="input"
                                        type="password"
                                        placeholder="Password"
                                        onChange={formikk.handleChange}
                                        onBlur={formikk.handleBlur}
                                        value={formikk.values.password}
                                        isInvalid={!!formikk.errors.password}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {formikk.errors.password}
                                      </Form.Control.Feedback>
                                    </FloatingLabel>
                                  </Col>
                                </Row>
                              </Form>
                            </>
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          size="lg"
                          variant="primary"
                          className="fw-semibold"
                          onClick={() => {
                            formikk.submitForm();
                          }}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </div>
                  )}
                </Formik>
              </Modal>
            </Tab>
            {/* Edit Teacher End*/}

            {/* Advisor Teachers List Start*/}
            <Tab eventKey="advisorTeachersList" title="Advisor Teachers">
              <Container fluid>
                <Card
                  className="mt-5 ms-3 me-3 mb-5 text-center border border-3 shadow-sm bg-body rounded"
                  border="warning"
                >
                  <Card.Header className="fw-bold p-3" bg="primary" as="h5">
                    Advisor Teachers List
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped bordered hover>
                      <thead>
                        <tr>
                          <th>Name</th>

                          <th>Ssn</th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {list3.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <span>
                                {item.teacherName} {item.teacherSurname}
                              </span>
                            </td>
                            <td>
                              <span>{item.teacherSSN}</span>
                            </td>

                            {/* <td>
                              <span>
                                <Button
                                  variant="danger"
                                  onClick={() =>
                                    handleDelete2(item.advisorTeacherId)
                                  }
                                >
                                  <i className="fa-solid fa-trash" />
                                </Button>
                              </span>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row>
                      <Col className="d-flex justify-content-start ps-5">
                        Page {currentPage} of {totalPages}
                      </Col>
                      <Col>
                        <Pagination className="d-flex justify-content-end pe-3">
                          <Pagination.First onClick={showFirstPage} />
                          <Pagination.Prev onClick={showPrevPage} />

                          <Pagination.Item active>
                            {currentPage}
                          </Pagination.Item>

                          <Pagination.Next onClick={showNextPage} />
                          <Pagination.Last onClick={showLastPage} />
                        </Pagination>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Container>
              {/* Advisor Teachers List End*/}
            </Tab>
          </Tabs>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default TeacherPage;
