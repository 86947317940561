import React from "react";
import Content from "./layout/Content";
import Footer from "./layout/Footer";
import Header from "./layout/Header";

function App() {
  return (
    <div className="app">
      
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
