import React from 'react';
import blogData from "./../Blog/Blogs.json";
import blog01 from "./../../../assets/images/blog/blog_01.jpg";
import blog02 from "./../../../assets/images/blog/blog_02.jpg";
import blog03 from "./../../../assets/images/blog/blog_03.jpg";

const FromOurBlog = () => {
    const imgData = [blog01, blog02, blog03];
    const slicedBlogData = blogData.slice(0, 3);

    return (
        <div>
            <section className="blog padding-120">
                <div className="container">
                    <div className="section-header">
                        <h3>From Our Blog</h3>
                        <p>
                            Our blog page, created to encourage our students to blog, is brimming with captivating content
                        </p>
                    </div>
                    <div className="blog-items">
                        <div className="row">
                            {slicedBlogData.map((blog, index) => (
                                <div className="col-md-4 col-sm-6 col-xs-12 " key={index}>
                                    <div className="blog-item h-100">
                                        <div className="blog-image">
                                            <a href="single.html">
                                                <img
                                                    src={imgData[index]}
                                                    alt={`Blog_Image ${index + 1}`}
                                                    className="img-responsive"
                                                />
                                            </a>
                                        </div>
                                        <div className="blog-content h-100">
                                            <h4>
                                                <a href="single.html">{blog.title}</a>
                                            </h4>
                                            <p>{blog.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FromOurBlog;
